<template>
	<div style="height: calc(100vh - 241px)">
		<!-- <v-sheet class="recurring-main-listing"> -->
		<v-layout class="mb-1">
			<v-row class="d-flex align-center">
				<v-select
					v-model="filter.month"
					class="listing-select pr-0"
					hide-details
					:disabled="pageLoading"
					:items="monthList"
					solo
					item-text="title"
					item-value="value"
					style="max-width: 175px !important; min-width: 175px !important"
					:menu-props="{ offsetY: true, bottom: true }"
					@change="getkwExcel()"
				>
					<template v-slot:item="{ item }">
						<v-list-item-content class="p-0">
							<v-list-item-title v-text="item.title"></v-list-item-title>
						</v-list-item-content>
					</template>
				</v-select>

				<v-select
					v-model="filter.year"
					class="listing-select year"
					hide-details
					:disabled="pageLoading"
					:items="yearList"
					solo
					item-text="title"
					item-value="value"
					style="max-width: 110px !important; min-width: 110px !important"
					:menu-props="{ offsetY: true, bottom: true }"
					@change="getkwExcel()"
				>
					<template v-slot:item="{ item }">
						<v-list-item-content>
							<v-list-item-title v-text="item.title"></v-list-item-title>
						</v-list-item-content>
					</template>
				</v-select>

				<v-text-field
					right
					class="ml-3"
					ref="search"
					v-model.trim="filter.search"
					autocomplete="off"
					hide-details
					placeholder="search"
					outlined
					@keydown.esc="onEsc"
					@keydown.enter="getKeywords()"
					@input="searchKeyword"
					style="max-width: 280px; min-width: 250px"
				>
					<template #prepend-inner>
						<v-icon color="grey" class="ml-1 mr-2"> mdi-magnify </v-icon>
					</template>
				</v-text-field>
				<v-btn
					depressed
					color="white"
					class="blue darken-4"
					tile
					text
					small
					style="height: 30px"
					v-on:click="getKeywords()"
				>
					<v-icon>mdi-magnify</v-icon>
				</v-btn>
				<v-btn
					depressed
					color="white"
					class="red darken-4 mx-2"
					tile
					text
					small
					style="height: 30px; min-width: 0px !important"
					v-on:click="resetSearch"
				>
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-row>
			<v-flex class="py-0 my-auto d-flex justify-end text-right listing-right">
				<!-- <template> -->
				<!-- <v-flex class="d-flex justify-content-end mr-1"> </v-flex> -->
				<!-- <v-flex class="d-flex justify-content-end mr-1">
						<DatePicker
							hide-details
							outlined
							custom-class="mt-0"
							v-model="actula_date_filter"
							placeholder="Actual Date"
							v-on:change="actualDateFilter($event)"
						>
						</DatePicker>
					</v-flex>
 -->
				<template>
					<template v-if="getPermission('keyword_analysis:create')">
						<v-tooltip top>
							<template #activator="{ on, attrs }">
								<v-btn
									depressed
									:disabled="pageLoading"
									tile
									color="blue darken-2"
									class="text-white"
									v-on="on"
									v-bind="attrs"
									href="/media/sample-files/Sample_keyword_analysis.xlsx"
									download
								>
									<!-- @click="downloadSample" -->
									<v-icon left> mdi-download </v-icon>
									Sample CSV
								</v-btn>
							</template>
							<span>Download Sample CSV File</span>
						</v-tooltip>

						<v-tooltip top>
							<template #activator="{ on, attrs }"
								><v-btn
									depressed
									:disabled="pageLoading"
									tile
									color="blue darken-4"
									class="text-white"
									v-on:click="importDialog = true"
									v-on="on"
									v-bind="attrs"
								>
									<v-icon left> mdi-plus </v-icon>
									Upload File
								</v-btn>
							</template>
							<span>Upload CSV File</span>
						</v-tooltip>
					</template>

					<v-tooltip top nudge-left="35">
						<template #activator="{ on, attrs }">
							<v-btn
								depressed
								:disabled="pageLoading"
								tile
								color="green darken-4"
								class="text-white"
								v-on="on"
								v-bind="attrs"
								@click="exportCSV(csvDownloadLink)"
							>
								<v-icon left> mdi-table-arrow-down </v-icon>
								CSV
							</v-btn>
						</template>
						<span>Download CSV File</span>
					</v-tooltip>
				</template>
				<!-- </template> -->
			</v-flex>
		</v-layout>
		<div style="max-height: calc(100vh - 297px); overflow-y: auto; overflow-x: hidden">
			<!-- :height="excelHeight" -->
			<div class="seo-tabs">
				<v-simple-table
					width="100%"
					fixed-header
					class="detail-table normal-table table-head-sticky mb-5"
				>
					<thead>
						<tr>
							<th width="5%" class="p-2 light-blue-bg custome-table">#</th>
							<th width="20%" class="p-2 light-blue-bg custome-table">Analysis Code</th>
							<th width="20%" class="p-2 light-blue-bg custome-table">Date</th>
							<th width="5%" class="p-2 light-blue-bg custome-table">Action</th>
						</tr>
					</thead>
					<tbody v-if="kwExcel.length">
						<tr
							v-for="(row, index) in kwExcel"
							:class="['cursor-pointer', { 'grey lighten-3': row.id === activeRowId }]"
							@click="rowActive(row)"
							:key="row.name + '_' + index"
						>
							<td width="5%" class="p-2 border-top-light-grey">
								<!-- <ShowValue :object="row" object-key="id" label="sr"></ShowValue> -->
								<!-- {{ index + 1 }} -->
								{{ index + 1 }}
							</td>
							<td width="20%" class="p-2 border-top-light-grey">
								<!-- <ShowValue :object="row" object-key="barcode" label="barcode" class="text-capitalize"></ShowValue> -->
								<Chip :text="row.barcode" color="cyan" v-if="row.barcode"></Chip>
							</td>
							<td width="20%" class="p-2 border-top-light-grey">
								<span>{{ formatDateTime(row.added_at) }}</span>
							</td>
							<td width="10%" class="px-0 border-top-light-grey">
								<v-tooltip top>
									<template #activator="{ on, attrs }">
										<v-btn
											v-on:click.stop="doAction(row, 'download')"
											icon
											depressed
											color="blue darken-4"
											class=""
											v-on="on"
											v-bind="attrs"
											:ripple="false"
										>
											<v-icon size="25">mdi-download</v-icon>
										</v-btn>
									</template>
									<span>{{ row.name }}</span>
								</v-tooltip>
								<!-- v-on:click.stop.prevent="deleteConfirm(index)" -->
								<template
									v-if="
										getPermission('keyword_analysis:delete') &&
										AllowAnyOforGetRoleNType(['master', 'admin', 'manager', 'super_admin', 'team_lead'])
									"
								>
									<v-tooltip top content-class="custom-top-tooltip">
										<template v-slot:activator="{ on, attrs }">
											<v-btn
												v-bind="attrs"
												v-on="on"
												icon
												depressed
												:ripple="false"
												@click="doAction(row, 'delete')"
											>
												<v-icon size="25" class="cursor-pointer" color="red"> mdi-delete </v-icon>
											</v-btn>
										</template>
										<span>{{ row.name }}</span>
									</v-tooltip>
								</template>
							</td>
						</tr>
					</tbody>
					<tfoot v-else>
						<tr>
							<td :colspan="5">
								<p class="m-0 row-not-found text-center py-3">
									<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
									Uhh... There <span class="text-lowercase">are no file at the moment.</span>
								</p>
							</td>
						</tr>
					</tfoot>
				</v-simple-table>
			</div>
			<!-- :height="mappingHeight" -->

			<div class="table-detail">
				<v-simple-table width="100%" class="detail-table normal-table table-head-sticky">
					<thead>
						<tr>
							<th width="10%" class="p-2 light-blue-bg custome-table">#</th>
							<!-- <th width="5%" class="p-2 light-blue-bg custome-table text-center">Actions</th> -->
							<th width="20%" class="p-2 light-blue-bg custome-table">Keyword</th>
							<th width="20%" class="p-2 light-blue-bg custome-table">Avg Searches</th>
							<th width="25%" class="p-2 light-blue-bg custome-table">Suggested Bid</th>
							<th width="25%" class="p-2 light-blue-bg custome-table">Position On Start</th>
						</tr>
					</thead>
					<tbody>
						<template v-if="pageLoading">
							<tr>
								<td colspan="7" width="100%" class="mx-20 my-40 text-center">
									<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
								</td>
							</tr>
						</template>

						<template v-else-if="activeRowId != null && kwAnalysis && kwAnalysis.length > 0">
							<tr v-for="(row, index) in kwAnalysis" :key="index">
								<td width="10%" class="p-2 border-top-light-grey custome-table-td">
									<!-- <ShowValue :object="row" object-key="id" label="sr"></ShowValue> -->
									<!-- {{ per_page * (currentPage - 1) + index + 1 }} -->
									{{ index + 1 }}
								</td>
								<!-- <td width="5%" class="p-2 border-top-light-grey text-center custome-table-td">
									v-on:click.stop.prevent="deleteConfirm(index)"
									<v-tooltip top content-class="custom-top-tooltip">
										<template v-slot:activator="{ on, attrs }">
											<v-icon v-bind="attrs" v-on="on" size="25" class="cursor-pointer" color="red"
												>mdi-delete</v-icon
											>
										</template>
										<span>Delete</span>
									</v-tooltip>
								</td> -->

								<td width="20%" class="p-2 border-top-light-grey custome-table-td">
									<ShowValue :object="row" object-key="keyword" label="keyword"></ShowValue>
								</td>
								<td width="10%" class="p-2 border-top-light-grey custome-table-td">
									<!-- <ShowValue :object="row" object-key="avg_searches" label="avg_searches"></ShowValue> -->

									<div class="d-flex">
										<v-chip
											v-if="row.msv_trafic"
											class="text-capitalize rounded-sm"
											color="cyan darken-4"
											outlined
											:class="['p-1', 'justify-center']"
											style="min-width: 50px !important"
										>
											{{ row.msv_trafic }}
										</v-chip>
									</div>
								</td>
								<!-- <td width="6%" class="p-2 border-top-light-grey custome-table-td">
									<Chip
										class="text-capitalize"
										:text="row.status"
										:color="statusColor(row.status)"
										text-color=""
										outlined
									></Chip>
								</td> -->
								<td width="15%" class="p-2 border-top-light-grey custome-table-td">
									<!-- <ShowValue :object="row" object-key="suggested_bid" label="suggested_bid"></ShowValue> -->
									<Chip
										class="text-capitalize text-center"
										v-if="row.suggested_bid"
										:text="row.suggested_bid"
										color="green darken-4"
										text-color=""
										outlined
										:inlineStyle="{ minWidth: '50px' }"
										:customClass="['p-1']"
									></Chip>
								</td>
								<td width="20%" class="p-2 border-top-light-grey custome-table-td">
									<!-- <ShowValue :object="row" object-key="current_rank" label="current_rank"></ShowValue> -->
									<Chip
										class="text-capitalize"
										v-if="row.current_rank"
										:text="row.current_rank"
										:color="isNaN(+row.current_rank) ? 'red darken-4' : 'green darken-4'"
										text-color=""
										outlined
										:width="isNaN(+row.current_rank) ? '100' : '45'"
										:customClass="['p-1', 'justify-center']"
									></Chip>
								</td>
								<!-- <td width="22%" class="p-2 border-top-light-grey custome-table-td">
									<ShowValue :object="row" object-key="h1" label="h1"></ShowValue>
								</td> -->
								<!-- <td width="10%" class="p-2 border-top-light-grey custome-table-td">
									<ShowValue :object="row" object-key="top_20" label="top_20"></ShowValue>
								</td> -->

								<!-- <td width="10%" class="p-2 border-top-light-grey custome-table-td">
									<ShowValue :object="row" object-key="top_10" label="top_10"></ShowValue>
								</td>
								<td width="10%" class="p-2 border-top-light-grey custome-table-td">
									<ShowValue :object="row" object-key="top_20" label="top_5"></ShowValue>
								</td>
								<td width="10%" class="p-2 border-top-light-grey custome-table-td">
									<ShowValue :object="row" object-key="top_10" label="top_3"></ShowValue>
								</td> -->
							</tr>
						</template>

						<template v-else>
							<tr>
								<td :colspan="7">
									<p class="m-0 row-not-found text-center py-3">
										<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
										Uhh... There <span class="text-lowercase">are no file at the moment.</span>
									</p>
								</td>
							</tr>
						</template>
					</tbody>
				</v-simple-table>
			</div>
			<!-- <Table
			type="seo_analysis"
			delete-endpoint="seo_analysis/"
			v-on:reload:content="filterRows"
			v-on:update:dialog="getInvoicedata"
			delete-note="All transactions of this member will also be deleted."
		></Table> -->
			<ImportDialog
				endpoint="seo-keyword-analysis/import"
				title="Keyword Analysis"
				type="keyword_analysis"
				v-if="importDialog && getPermission('keyword_analysis:create')"
				:import-dialog.sync="importDialog"
				v-on:close="importDialog = false"
				v-on:refress="getkwExcel"
				:project-data-id="projectId"
				:seo-data-id="seoId"
				sample-file="/media/sample-files/Sample_keyword_analysis.xlsx"
			></ImportDialog>
			<DeleteTemplate
				v-if="deleteDialog && getPermission('keyword_analysis:delete')"
				type="Keyword Analysis"
				delete-text="Keyword"
				v-on:success="getkwExcel"
				v-on:close="deleteDialog = false"
				:delete-dialog="deleteDialog"
				:delete-url="`seo/project/keyword-analysis/${id}?type=keyword_analysis`"
			>
			</DeleteTemplate>
			<!-- <v-row class="my-2">
				<v-col md="6" class="my-auto">
					<p class="m-0">{{ showingString }}</p>
				</v-col>
				<v-col md="6" class="text-right">
					<v-pagination
						color="blue darken-4"
						v-model="page"
						:length="totalPage"
						total-visible="9"
						v-on:input="updatePagination($event)"
					></v-pagination>
				</v-col>
			</v-row> -->
		</div>
		<!-- </v-sheet> -->
	</div>
</template>
<style>
.custome-table {
	background-color: #f3f6f9;
	border-bottom: thin solid rgba(0, 0, 0, 0.12);
	border-right: 1px solid #e2e5ec !important;
}
.custome-table-td {
	border-right: 1px solid #e2e5ec !important;
}
.border-top-light-grey {
	border-top: solid 1px #e2e5ec !important;
	/*  border-bottom: solid 1px #bcc0c9 !important; */
}
:is(.normal-table) thead tr th,
tbody tr td {
	font-size: 13px !important;
	color: rgba(0, 0, 0, 0.87) !important;
}

.normal-table thead tr th {
	font-weight: 500 !important;
}
.normal-table tbody tr td {
	font-weight: 400 !important;
}
.normal-table tbody tr:nth-child(odd) {
	background-color: #e7f4ff;
}

.seo-tabs .v-data-table__wrapper {
	max-height: 333px;
	overflow-y: auto !important;
}
</style>

<script>
/* import Dialog from "@/view/components/Dialog"; */
import MainMixin from "@/core/mixins/main.mixin";
/* import { QUERY } from "@/core/services/store/request.module";
import { SET_TBODY } from "@/core/services/store/listing.module"; */
import ImportDialog from "@/view/components/ImportDialogSEOTabs.vue";
import ApiService from "@/core/services/api.service";
import ShowValue from "@/view/components/ShowValue";
import DeleteTemplate from "@/view/components/DeleteTemplate";
import JwtService from "@/core/services/jwt.service";

import Chip from "@/view/components/Chip";
import { mapGetters } from "vuex";
import { debounce } from "lodash";

// import {
// 	SET_CURRENT_PAGE,
// 	SET_SHOWING_STRING,
// 	SET_TOTAL_PAGE,
// } from "@/core/services/store/listing.module";
export default {
	name: "Seo-Analysis-listing",
	title: "Listing Invoice",
	mixins: [MainMixin],
	data() {
		return {
			filter: {
				month: "",
				year: new Date().getFullYear().toString(),
				search: null,
			},
			// excelHeight: (document.body.clientHeight / 100) * 34,
			// mappingHeight: (document.body.clientHeight / 100) * 34,
			month_menu: false,
			year_menu: false,
			yearList: [],
			monthList: [
				{ index: 0, title: "All MONTH", value: "" },
				{ index: 1, title: "January", value: "01" },
				{ index: 2, title: "February", value: "02" },
				{ index: 3, title: "March", value: "03" },
				{ index: 4, title: "April", value: "04" },
				{ index: 5, title: "May", value: "05" },
				{ index: 6, title: "June", value: "06" },
				{ index: 7, title: "July", value: "07" },
				{ index: 8, title: "August", value: "08" },
				{ index: 9, title: "September", value: "09" },
				{ index: 10, title: "October", value: "10" },
				{ index: 11, title: "November", value: "11" },
				{ index: 12, title: "December", value: "12" },
			],

			pageTitle: "Seo-Analysis",
			pageBreadcrumbs: [{ text: "Seo-Analysis", disabled: true }],
			activeRowId: 1,
			endpoint: "seo-analysis",
			defaultFilter: {},
			count: {},
			status: "all",
			pageLoading: false,
			importDialog: false,
			kwAnalysis: [],
			kwExcel: [],
			page: 1,
			showing_string: null,
			totalRows: 0,
			rowsOffset: 0,
			totalPages: 0,
			totalSelected: 0,
			paginationPageSize: 0,
			deleteDialog: false,
			deleteURL: null,
			per_page: 5,
			total_page: null,
			id: null,
			seoId: null,
			csvDownloadLink: null,
			timer: null,
		};
	},
	props: {
		projectId: {
			type: Number,
			default: 0,
		},
		fileName: {
			type: String,
			default: "",
		},
	},
	/* watch: {
		relatedId() {
			this.getListing();
		},
		updatedData() {
			this.getListing();
		},
	}, */
	watch: {
		// "filter.month": {
		// 	handler: function () {
		// 		this.getKeywords();
		// 	},
		// },
		// "filter.year": {
		// 	handler: function () {
		// 		this.getKeywords();
		// 	},
		// },
		"filter.search": {
			handler: function () {
				let run = debounce(function () {
					this.getkwExcel();
				}, 1000);
				run();
			},
		},
	},
	methods: {
		searchKeyword() {
			clearTimeout(this.timer);
			this.timer = setTimeout(() => {
				this.getKeywords();
			}, 600);
		},
		rowActive(row) {
			if (row.id != this.activeRowId) {
				this.activeRowId = row.id;
				this.csvDownloadLink = row.url;
				this.getKeywords();
			}
		},
		statusColor(value) {
			return +value >= 200 && +value < 300
				? "green"
				: +value >= 300 && +value < 400
				? "orange"
				: "red";
		},
		exportCSV(url = null) {
			ApiService.setHeader();
			let downloadURL = url
				? url
				: this.activeRowId
				? process.env.VUE_APP_API_URL +
				  `seo/${this.seoId}/project/${this.projectId}/url-mapping-export?url_map_id=${this.activeRowId}`
				: "";

			console.log({ downloadURL });
			downloadURL = new URL(downloadURL);
			const token = JwtService.getToken();
			downloadURL.searchParams.append("token", token);
			const project_name = this.fileName;

			const customName =
				project_name +
				" - keyword analysis (" +
				this.formatDateTimeRawWithoutTime(new Date()) +
				").csv";

			fetch(downloadURL)
				.then((response) => response.blob())
				.then((blob) => {
					const blobURL = URL.createObjectURL(blob);
					const anchor = document.createElement("a");
					anchor.href = blobURL;
					anchor.setAttribute("download", customName);
					document.body.appendChild(anchor);
					anchor.click();
					document.body.removeChild(anchor);
					URL.revokeObjectURL(blobURL);
				});
		},
		doAction(row, param) {
			switch (param) {
				case "download":
					this.exportCSV(row.url);
					break;
				case "delete":
					this.id = row.id;
					// var id = row.id;
					// this.deleteUrl = `seo/project/keyword-analysis/${id}`;
					this.deleteDialog = true;
					break;
			}
		},
		getKeywords(activeId = this.activeRowId.toString()) {
			this.pageLoading = true;
			const payload = {
				keyword_analysis_id: activeId,
				sort: "asc",
				status: null,
				// current_page: this.currentPage.toString(),
				per_page: 10000,
				search: this.filter.search || null,
			};
			ApiService.query(`seo/${this.seoId}/project/${this.projectId}/seo-keyword-analysis`, payload)
				.then(({ data }) => {
					this.kwAnalysis = data;
					// this.showing_string = data.showing_String;
					// this.total_page = data.totalPages;
					// this.$store.commit(SET_SHOWING_STRING, this.showing_string);
					// this.$store.commit(SET_TOTAL_PAGE, this.total_page);
				})
				.catch((error) => {
					this.$emit("error", error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		getkwExcel() {
			this.pageLoading = true;
			ApiService.query(
				`seo/${this.seoId}/project/${this.projectId}/excel-seo-keyword-analysis`,
				this.filter
			)
				.then(({ data, status }) => {
					this.kwExcel = data || [];
					this.csvDownloadLink = (data && data[0] && data[0].url) || null;
					if (status && data.length > 0) {
						let id = data && data.length > 0 ? data[0].id : null;
						this.activeRowId = id;
						this.getKeywords(id);
					} else {
						this.activeRowId = null;
					}
				})
				.catch((error) => {
					this.$emit("error", error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		// updatePagination(param) {
		// 	if (this.currentPage == param) {
		// 		return false;
		// 	}

		// 	this.$store.commit(SET_CURRENT_PAGE, param);
		// 	this.$nextTick(() => {
		// 		this.getKeywords();
		// 	});
		// },
		onEsc() {
			this.resetSearch();
		},
		resetSearch() {
			const _this = this;
			_this.$nextTick(() => {
				_this.filter.search = null;
				this.getKeywords();
			});
		},
		getYears() {
			let current_year = +new Date().toISOString().slice(0, 4);
			let _yearList = [];
			while (_yearList.length < 10) {
				let obj = {
					title: current_year.toString(),
					index: _yearList.length,
					value: current_year.toString(),
				};
				_yearList.push(obj);
				current_year = current_year - 1;
			}
			this.yearList = [..._yearList];
		},
	},
	components: {
		ImportDialog,
		ShowValue,
		Chip,
		DeleteTemplate,
	},
	mounted() {
		if (this.$route.params.id) {
			this.seoId = this.$route.params.id;
			this.getkwExcel();
		}
		// this.getSeo();
		// this.getkwExcel();
		this.getYears();
	},
	computed: {
		...mapGetters(["selected", "tbody", "currentPage", "totalPage", "showingString"]),
	},
};
</script>
